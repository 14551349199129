const ReportRoutes = [
    {
        path: 'reprt/oee_planta',
        component: () => import('@/components/sbx/reports/controlfloor/oee/oee_planta')
    },
    {
        path: 'reprt/oeeworkgroup',
        component: () => import('@/components/sbx/reports/controlfloor/oee/OEEGroupWork')
    },
    {
        path: 'reprt/oeeworkstationdetails',
        component: () => import('@/components/sbx/reports/controlfloor/oee/OEEWorkPlaceDetalle.vue')
    },
    // {
    //   path: 'reprt/workstationdetails',
    //   component: () => import('@/components/sbx/reports/controlfloor/OrdnProduccion/DetalleCentroTrabajo')
    // },
    {
        path: 'reprt/detalleOrdenProduccion/:id',
        component: () => import('@/components/sbx/reports/controlfloor/OrdnProduccion/OrdenProduccion'), name: 'detalleOrdenProduccion'
    },
    {
        path: 'reprt/reportproduction',
        component: () => import('@/components/sbx/reports/controlfloor/OrdnProduccion/ReportesProduccion'), name: 'reportproduction'
    },
    {
        path: 'reprt/products',
        component: () => import('@/components/sbx/reports/controlfloor/Productos/Productos')
    },
    {
        path: 'reprt/productiontime',
        component: () => import('@/components/sbx/reports/controlfloor/Productos/ProductosRangoTiempo')
    },
    {
        path: 'reprt/stopcodepergroup',
        component: () => import('@/components/sbx/reports/controlfloor/StopTimes/StopCodeGroup')
    },
    {
        path: 'reprt/general',
        component: () => import('@/components/sbx/reports/controlfloor/totals/totalesgenerales')
    },
    {
        path: 'reprt/stopcodeperworkstation',
        component: () => import('@/components/sbx/reports/controlfloor/StopTimes/StopCodeWorkPlace')
    },
    {
        path: 'reprt/detailworkstation',
        component: () => import('@/components/sbx/reports/controlfloor/OrdnProduccion/endShift/EndShiftView')
    },
    {
        path: 'reprt/executiveendofshift',
        component: () => import('@/components/sbx/reports/controlfloor/OrdnProduccion/endShift/EndShiftView')
    },
    {
        path: 'reprt/resumenoeedetal',
        component: () => import('@/components/sbx/reports/controlfloor/StopTimes/OeeDetallado')
    },
    {
        path: 'reprt/cmp',
        component: () => import('@/components/sbx/reports/inventory/infomp')
    },
    {
        path: 'reprt/calls',
        component: () => import('@/components/sbx/reports/calls/calls')
    },
    {
        path: 'reprt/preshift',
        component: () => import('@/components/sbx/reports/planning/preshift')
    },
    {
        path: 'mttoreport/disp',
        component: () => import('@/components/sbx/reports/mtto/disponibilidad')
    },
    {
        path: 'mttoreport/mtbf',
        component: () => import('@/components/sbx/reports/mtto/mtbf')
    },
    {
        path: 'mttoreport/mttr',
        component: () => import('@/components/sbx/reports/mtto/mttr')
    },
    {
        path: 'mttoreport/mttrmtbf',
        component: () => import('@/components/sbx/reports/mtto/mttrmtbf')
    },
    {
        path: 'mttoreport/mttrmtbfmonth',
        component: () => import('@/components/sbx/reports/mtto/mttrmtbflastmonths')
    },
]

export {
    ReportRoutes
}