<template>
  <b-navbar-nav class="align-items-lg-center">
    <b-nav-item-dropdown icon="ion ion-ios-albums">
      <template slot="button-content">
        <span
          class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
        >
          <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
            $t("commercialModuleLabel")
          }}</span>
        </span>
      </template>
      <b-dd-item
        v-if="check_access('general_customer', 'r')"
        to="/cfgn/poli"
        :exact="true"
        >{{ $t("commercialModule_Label") }}</b-dd-item
      >
      <b-dd-item
        v-if="check_access('general_customer', 'r')"
        to="/cfgn/customer"
        :exact="true"
        >{{ $t("customersLabel") }}</b-dd-item
      >
      <b-dd-item
        v-if="check_access('general_providers', 'r')"
        to="/cfgn/supplier"
        :exact="true"
        >{{ $t("suppliersLabel") }}</b-dd-item
      >
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
export default {
  props: {
    check_access: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
