<template>
  <b-navbar-nav class="align-items-lg-center">
    <b-nav-item-dropdown icon="ion ion-ios-albums">
      <template slot="button-content">
        <span
          class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
        >
          <!-- <img :src="require('@/vendor/avatars/default.png')" alt class="d-block ui-w-30 rounded-circle"> -->

          <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
            $t("logbookLabel")
          }}</span>
        </span>
      </template>
      <!-- <b-dd-item class="ml-0" to="/btc/btc" :exact="true">Bitácora</b-dd-item> -->
      <b-dd-item class="ml-0" to="/btc/clst" :exact="true">{{
        $t("shiftClosureLabel")
      }}</b-dd-item>

      <b-dd-item
        class="ml-0"
        v-for="(item, index) in binnacle"
        :key="index"
        :to="`/btc/${item.code}`"
        :exact="true"
        >{{ item.text }}</b-dd-item
      >
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
import { infosysconfig } from "@/components/i40/js/sysconfig";

export default {
  data() {
    return {
      binnacle: [],
    };
  },
  beforeCreate() {
    infosysconfig.functionalarea([], "select").then((data) => {
      this.binnacle = [];
      if (data.data != "") {
        for (let index = 0; index < data.data.length; index++) {
          const element = data.data[index];
          if (element.binnacle_active)
            this.binnacle.push({
              text: element.description,
              code: element.binnacle_code,
            });
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped></style>
