<template>
  <b-navbar-nav class="align-items-lg-center">
    <b-nav-item-dropdown icon="ion ion-ios-albums">
      <template slot="button-content">
        <span
          class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
        >
          <!-- <img :src="require('@/vendor/avatars/default.png')" alt class="d-block ui-w-30 rounded-circle"> -->

          <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{ $t("toolsLabel") }}</span>
        </span>
      </template>
      <!-- <b-dd-item class="ml-0" to="/btc/btc" :exact="true">Bitácora</b-dd-item> -->
      <b-dd-item class="ml-0" to="/rt/calls" :exact="true">{{
        $t("callsLabel")
      }}</b-dd-item>
      <b-dd-divider></b-dd-divider>
      <b-dd-item to="/rt/timeseries" :exact="true">{{
        $t("seriesQueryLabel")
      }}</b-dd-item>
      <b-dd-item to="/rt/rttimeseries" :exact="true">{{
        $t("realTimeSeriesLabel")
      }}</b-dd-item>
      <b-dd-item to="/rt/alarms" :exact="true">{{
        $t("alarmVisualizationLabel")
      }}</b-dd-item>
      <b-dd-item to="/rt/order_programming" :exact="true">{{
        $t("quickOrderCreate")
      }}</b-dd-item>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
