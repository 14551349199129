const InventRoutes = [
    {
        path: '/invent',
        component: () => import('@/components/sbx/inventory/invent_start.vue')
    },
    {
        path: '/invent/movs',
        component: () => import('@/components/sbx/inventory/control_movs.vue')
    },
    {
        path: '/invent/kardex',
        component: () => import('@/components/sbx/inventory/kardex.vue')
    },
    {
        path: '/invent/detubq',
        component: () => import('@/components/sbx/inventory/invubq.vue')
    },
    {
        path: '/invent/invgral',
        component: () => import('@/components/sbx/inventory/invtotal.vue')
    },
    {
        path: '/invent/details',
        component: () => import('@/components/sbx/trade/commercial_orders.vue')
    },

]

export {
    InventRoutes
}