<template>
  <b-navbar-nav class="align-items-lg-center">
    <b-nav-item-dropdown icon="ion ion-ios-albums">
      <template slot="button-content">
        <span
          class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
        >
          <span class="px-1 mr-lg-2 ml-2 ml-lg-0">
            {{ $t("managementOptions") }}
          </span>
        </span>
      </template>
      <b-dd-item class="ml-0" to="/mnt/register" :exact="true">{{
        $t("informationLoggingLabel")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/mnt/invonl" :exact="true">{{
        $t("onlineInventoryLabel")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/mnt/purch" :exact="true">{{
        $t("purchasesLabel")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/mnt/businesses" :exact="true">{{
        $t("businessesTitle")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/mnt/indicators" :exact="true">{{
        $t("indicatorsLabel")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/mnt/operators" :exact="true">{{
        $t("operatorsLabel")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/mnt/ot-assignment" :exact="true">{{
        $t("taskAssignment")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/mnt/my-tasks" :exact="true">{{
        $t("myAssignments")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/mnt/materials" :exact="true">{{
        $t("materials")
      }}</b-dd-item>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
