const SysconfigRoutes = [
    {
        path: 'bcf/tooltype',
        component: () => import('@/components/sbx/sysconfig/tools_types')
    },
    {
        path: 'bcf/areas',
        component: () => import('@/components/sbx/config/functional_areas')
    },
    {
        path: 'bcf/um',
        component: () => import('@/components/sbx/sysconfig/um')
    },
    {
        path: 'bcf/forms',
        component: () => import('@/components/sbx/sysconfig/forms')
    },
    {
        path: 'bcf/workstation_types',
        component: () => import('@/components/sbx/sysconfig/workstation_types')
    },
    {
        path: 'bcf/documents_types',
        component: () => import('@/components/sbx/sysconfig/documents_types')
    },
    {
        path: 'bcf/mov_types',
        component: () => import('@/components/sbx/sysconfig/mov_types')
    },
    {
        path: 'bcf/btctpa',
        component: () => import('@/components/sbx/sysconfig/bitacora_tipo_actividad')
    },
    {
        path: 'bcf/documentstatus',
        component: () => import('@/components/sbx/sysconfig/status_module')
    },
    {
        path: 'bcf/operator',
        component: () => import('@/components/sbx/production/operator')
    },
    {
        path: '/sys',
        component: () => import('@/components/sbx/infosbx/startinfo.vue')
    },
    {
        path: 'sys/task_scheduler',
        component: () => import('@/components/sbx/infosbx/task_scheduler')
    },
    {
        path: 'sys/devices',
        component: () => import('@/components/sbx/infosbx/InfoDevices')
    },
    {
        path: 'sys/infolicense',
        component: () => import('@/components/sbx/infosbx/InfoLicense')
    },
]

export {
    SysconfigRoutes
}