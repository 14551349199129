<template>
  <b-navbar-nav class="align-items-lg-center">
    <b-nav-item-dropdown icon="ion ion-ios-albums">
      <template slot="button-content">
        <span
          class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
        >
          <span class="px-1 mr-lg-2 ml-2 ml-lg-0">Costos</span>
        </span>
      </template>
      <b-dd-item
        class="ml-0"
        v-if="check_access('costs_opr', 'r')"
        to="/cst/syf"
        :exact="true"
        >Sociedades Financieras</b-dd-item
      >
      <b-dd-item
        class="ml-0"
        v-if="check_access('costs_opr', 'r')"
        to="/cst/clcc"
        :exact="true"
        >Clase de CC</b-dd-item
      >
      <b-dd-item
        class="ml-0"
        v-if="check_access('costs_opr', 'r')"
        to="/cst/tdc"
        :exact="true"
        >Tipos de Cuenta</b-dd-item
      >
      <b-dd-item
        class="ml-0"
        v-if="check_access('costs_opr', 'r')"
        to="/cst/puc"
        :exact="true"
        >Plan de Cuentas</b-dd-item
      >
      <b-dd-item
        class="ml-0"
        v-if="check_access('costs_opr', 'r')"
        to="/cst/mcc"
        :exact="true"
        >Maestros de Centros de Costos</b-dd-item
      >
      <b-dd-item
        class="ml-0"
        v-if="check_access('costs_opr', 'r')"
        to="/cst/ca"
        :exact="true"
        >Clase de Actividad</b-dd-item
      >
      <b-dd-item
        class="ml-0"
        v-if="check_access('costs_opr', 'r')"
        to="/cst/ep"
        :exact="true"
        >Esquema de Partición</b-dd-item
      >
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
export default {
  props: {
    check_access: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
