const UsersRoutes = [
    {
        path: 'users/users_edit',
        component: () => import('@/components/sbx/users/users_edit')
    },
    {
        path: 'users/users_edit/:id',
        component: () => import('@/components/sbx/users/users_edit')
    },
    {
        path: 'users/users_list',
        component: () => import('@/components/sbx/users/users_list')
    },
    {
        path: 'users/users_view',
        component: () => import('@/components/sbx/users/users_view')
    },
]

export {
    UsersRoutes
}