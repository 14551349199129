/**
 * Configuration file of the main server IP.
 */

import axios from "axios";
const protocolo = process.env.VUE_APP_PROTOCOL;

const serverIPs = {
    development: process.env.VUE_APP_DEV_IP,
    ojara: process.env.VUE_APP_OJARA_IP,
    kent: process.env.VUE_APP_KENT_IP,
    elgen: process.env.VUE_APP_ELGEN_IP,
    elgenTest: process.env.VUE_APP_ELGENTEST_IP,
    levacol: process.env.VUE_APP_LEVACOL_IP,
    cip: process.env.VUE_APP_CIP_IP,
    eafit: process.env.VUE_APP_EAFIT_IP,
    colanta: process.env.VUE_APP_COLANTA_IP,
    panal: process.env.VUE_APP_PANAL_IP,
    panal_test: process.env.VUE_APP_PANAL_TEST_IP,
    localHost: process.env.VUE_APP_LOCALHOST_IP,
    moldpet: process.env.VUE_APP_MOLDPET_IP,
    interplast: process.env.VUE_APP_INTERPLAST_IP,
    hlocation: window.location.hostname,
};

// change this for connection
const LOCATION = serverIPs["hlocation"]

// /http://localhost:8080/dsh/products/1676
var InfoConfig = {
    host: function () {
        let hst = LOCATION;

        if (JSON.parse(localStorage.getItem("syncbox.cloud")) != null)
            axios
                .post(
                    protocolo + "://" + hst + "/datacontroller" + "currentUser",
                    JSON.parse(localStorage.getItem("syncbox.cloud")).token
                )
                .then((response) => { })
                .catch((e) => {
                    localStorage.removeItem("syncbox.cloud");
                    this.$router.push(this.$route.query.redirect || "/login");
                });
        else {
            localStorage.removeItem("syncbox.cloud");
        }
        return hst;
    },

    login: function () {
        return (
            protocolo + `://` + LOCATION + `/datacontrollerloginx/`
        );
    },
};

let host = InfoConfig.host();

const testHost = "http://127.0.0.1:2200";

let datacontroller = protocolo + "://" + host + "/datacontroller";
let apiconnection = protocolo + "://" + host + "/apiconnectionrt";
let apirealtime = protocolo + "://" + host + "/apirealtimert";
let apirundb = protocolo + "://" + host + "/apirundb";

export {
    datacontroller,
    apirealtime,
    apiconnection,
    apirundb,
    InfoConfig,
    protocolo,
};

