<template>
  <b-navbar-nav class="align-items-lg-center">
    <b-nav-item-dropdown icon="ion ion-ios-albums">
      <template slot="button-content">
        <span
          class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
        >
          <!-- <img :src="require('@/vendor/avatars/default.png')" alt class="d-block ui-w-30 rounded-circle"> -->
          <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{ $t("textDrums") }}</span>
        </span>
      </template>
      <!-- <b-dd-item class="ml-0" to="/btc/btc" :exact="true">Bitácora</b-dd-item> -->
      <!-- <b-dd-item class="ml-0" to="/prd/searchop" :exact="true">Detalle Orden de Producción</b-dd-item>
              <b-dd-item class="ml-0" to="/prd/detailorders" :exact="true">Ver Orden de Producción</b-dd-item>
              <b-dd-item class="ml-0" v-if="check_access('drump_production', 'r')"  to="/prd/reprint_batches" :exact="true">Reimpresion de Lotes</b-dd-item> -->
      <b-dd-item
        class="ml-0"
        v-if="check_access('drump_production', 'r')"
        to="/prd/showops"
        :exact="true"
      >
        {{ $t("textViewOpenOrders") }}
      </b-dd-item>
      <b-dd-item
        class="ml-0"
        v-if="check_access('drump_production', 'r')"
        to="/prd/tacproduction"
        :exact="true"
      >
        {{ $t("textProductionTAC") }}
      </b-dd-item>
      <!-- <b-dd-item class="ml-0" v-if="check_access('drump_production', 'r')"  to="/prd/productionorders" :exact="true">Tambor de Producción</b-dd-item> -->
      <b-dd-item
        class="ml-0"
        v-if="check_access('drump_commercial', 'r')"
        to="/prd/orders"
        :exact="true"
      >
        {{ $t("textOrdersDrum") }}
      </b-dd-item>
      <b-dd-item
        class="ml-0"
        v-if="check_access('drump_purchase', 'r')"
        to="/prd/purchases"
        :exact="true"
      >
        {{ $t("textShoppingDrum") }}
      </b-dd-item>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
export default {
  props: {
    check_access: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
