<template>
  <b-navbar-nav class="align-items-lg-center">
    <b-nav-item-dropdown icon="ion ion-ios-albums">
      <template slot="button-content">
        <span
          class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
        >
          <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
            $t("maintenanceModuleConfigLabel")
          }}</span>
        </span>
      </template>
      <!-- <b-dd-item to="/cfmnt/groups/mnt" :exact="true">Grupo de Partes</b-dd-item> -->
      <b-dd-item to="/cfmnt/product/mnt" :exact="true">{{
        $t("partsRegister")
      }}</b-dd-item>
      <b-dd-item to="/cfpr/families/mnt" :exact="true">{{
        $t("familyOfParts")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/cfmnt/ra" :exact="true">{{
        $t("assetRegistration")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/cfmnt/ctg" :exact="true">{{
        $t("categories")
      }}</b-dd-item>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
