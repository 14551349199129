const ConfigurationRoutes = [
    {
        path: 'cfpr/families/:module',
        component: () => import('@/components/sbx/master/groups')
    },
    {
        path: 'cfpr/lines/:module',
        component: () => import('@//components/sbx/master/productsLine')
    },
    {
        path: 'cfpr/import/:op',
        component: () => import('@//components/sbx/master/import_info')
    },
    {
        path: 'cfpr/product/:module',
        component: () => import('@/components/sbx/master/products')
    },

    {
        path: 'cfpr/master/checkcodes',
        component: () => import('@/components/sbx/master/checkscodes')
    },
    // {
    //   path: 'cfpr/product/product_detail',
    //   component: () => import('@/components/sbx/master/details/product_detail')
    // }, 
    {
        path: 'cfpr/product/product_detail/:module/:idproduct',
        name: 'products_details',
        component: () => import('@/components/sbx/master/details/product_detail')
    },
    {
        path: 'cfgn/supplier',
        component: () => import('@/components/sbx/master/supplier')
    },
    {
        path: 'cfgn/customer',
        component: () => import('@/components/sbx/master/customers')
    },
    {
        path: 'cfgn/poli',
        component: () => import('@/components/sbx/master/commercial_policies')
    },
    ///Configuracion Rutas
    {
        path: 'cfpr/routes',
        component: () => import('@/components/sbx/config/routes')
    },
    {
        path: 'cfpr/routes/details',
        component: () => import('@/components/sbx/config/details/details_routes')
    },
    {
        path: 'cfpr/routes/details/:id/:routeproductid/:productid',
        component: () => import('@/components/sbx/config/details/details_routes')
    },
    ///Configuracion Rutas
    {
        path: 'cfsw/process',
        component: () => import('@/components/sbx/config/process_operations')
    },

    {
        path: 'cfws/tools',
        component: () => import('@/components/sbx/config/tools')
    },
    {
        path: 'cfws/workstations',
        component: () => import('@//components/sbx/master/workstation')
    },
    {
        path: 'cfws/plants',
        component: () => import('@//components/sbx/master/workstation_plant')
    },
    {
        path: 'cfws/wsgroup',
        component: () => import('@//components/sbx/master/workstation_group')
    },
    /// CONFIGURACIÓN PLANEACIÓN
    {
        path: 'cfpl/calendar',
        component: () => import('@/components/sbx/planning/config_capacity')
    },
    {
        path: 'cfpl/holidays',
        component: () => import('@/components/sbx/planning/config_holidays')
    },
    {
        path: 'cfpl/capacity',
        component: () => import('@/components/sbx/planning/config_workstation_capacity')
    },
    /////MAINTENANCE

    {
        path: 'cfmnt/ctg',
        component: () => import('@/components/sbx/maintenance/categories')
    },
    {
        path: 'cfmnt/groups/:module',
        component: () => import('@/components/sbx/master/groups')
    },
    {
        path: 'cfmnt/ra',
        component: () => import('@/components/sbx/maintenance/actives')
    },
    {
        path: 'cfmnt/acf/:id',
        name: 'assets_details',
        component: () => import('@/components/sbx/maintenance/actives_details')
    },
    {
        path: 'cfmnt/product/:module',
        component: () => import('@/components/sbx/master/products')
    },
    {
        path: 'cfmnt/product/product_detail/:module/:idproduct',
        name: 'products_details_mnt',
        component: () => import('@/components/sbx/master/details/product_detail')
    },
    /////CONTROL DE INVENTARIO
    {
        path: 'cfwh/warehouse',
        component: () => import('@/components/sbx/inventory/warehouse')
    },
    {
        path: 'cfwh/ubc',
        component: () => import('@/components/sbx/inventory/ubications')
    },
    /////CONTROL DE INVENTARIO
]

export {
    ConfigurationRoutes
}