const ProductionRoutes = [
    {
        path: 'prd/',
        component: () => import('@/components/sbx/production/production_start')
    },
    {
        path: 'prd/reprint_batches',
        component: () => import('@/components/sbx/production/reprint_batches')
    },
    {
        path: 'prd/showops',
        component: () => import('@/components/sbx/production/showops')
    },
    {
        path: 'prd/tacproduction',
        component: () => import('@/components/sbx/production/tacproduction')
    },
    {
        path: 'prd/productionorders',
        component: () => import('@/components/sbx/production/production')
    },
    {
        path: 'prd/searchop',
        component: () => import('@/components/sbx/production/search_production_order')
    },
    {
        path: 'prd/detailorders',
        component: () => import('@/components/sbx/production/showorders')
    },
    {
        path: 'prd/searchop/:orderid',
        component: () => import('@/components/sbx/production/search_production_order')
    },
    {
        path: 'prd/orders',
        component: () => import('@/components/sbx/commercial/drum_commercial/DrumCommercialView.vue')
        // component: () => import('@/components/sbx/trade/orders')
    },
    {
        path: 'prd/purchases',
        component: () => import('@/components/sbx/trade/purchases')
    },

]

export {
    ProductionRoutes
}