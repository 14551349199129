<template> <!-- Configuracion General -->
    <b-navbar-nav class="align-items-lg-center">
      <b-nav-item-dropdown icon="ion ion-ios-albums">
        <template slot="button-content">
          <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
            <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{ $t('generalLabel') }}</span>
          </span>
        </template>
        <b-dd-item v-if="check_access('admin_workstation_type', 'r')" to="/IoT/databases" :exact="true">
          {{ $t('iotNavbar.textDatabases') }}</b-dd-item>
        <b-dd-item v-if="check_access('admin_workstation_type', 'r')" to="/IoT/workstation_types" :exact="true">
          {{ $t('workCenterTypesLabel') }}</b-dd-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <!-- Configuracion General -->
  </template>
  
  <script>
  
  import { infousers } from '@/components/i40/js/users';
  export default {
    name: 'NewFrontv3IotLayout',
  
    data() {
      return {
        
      };
    },
  
    mounted() {
      
    },
  
    methods: {
      
  
      check_access(control, action) {
        return infousers.check_access(control, action)
        // return true
      },
  
    },
  };
  </script>