const DashboardRoutes = [
    {
        path: 'dsh/products/:idproduct',
        name: 'products-dashboard',
        component: () => import('@/components/sbx/dashboard/products/DashboardProductsView.vue')
    },
]

export {
    DashboardRoutes
}