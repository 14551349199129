<template>
  <sidenav :orientation="orientation" :class="curClasses">
<!-- {{layoutHelpers}} -->
    <!-- Brand demo (see src/demo.css) -->
    <div class="app-brand demo" v-if="orientation !== 'horizontal'">
      <!-- <span class="app-brand-logo demo bg-primary"> -->
        <img :src="`${publicUrl}img/logo.png`" class="w-75 ml-2 mt-2 mb-2" alt="">
      <!-- </span> -->
      <!-- <router-link to="/" class="app-brand-text demo sidenav-text font-weight-normal ml-2">Appwork</router-link> -->
      <a href="javascript:void(0)" class="layout-sidenav-toggle sidenav-link text-large ml-auto" @click="toggleSidenav()">
        <i class="ion ion-md-menu align-middle"></i>
      </a>
      <!-- {{userInfo.name + ' ' + userInfo.lastname}} -->
    </div>
    <div class="sidenav-divider mt-0" v-if="orientation !== 'horizontal'"></div>

    <!-- Links -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">
      <sidenav-router-link icon="ion ion-md-home" to="/" :exact="true">Home</sidenav-router-link>
      <sidenav-menu icon="fas fa-chart-pie" :active="isMenuActive('/dash')" :open="isMenuOpen('/dash')">
        <template slot="link-text">Dashboards</template>
        <!-- icon="fas fa-dollar-sign"  -->
        <sidenav-router-link to="/dash/gerencial" :exact="true" v-if="check_access('show_gerencial', 'r')">{{ $t('managerialLabel') }}</sidenav-router-link>
        <sidenav-router-link to="/dash/comm" :exact="true" v-if="check_access('show_comm', 'r')">{{ $t('commercialLabel') }}</sidenav-router-link>
        <sidenav-router-link to="/dash/cap" :exact="true" v-if="check_access('show_cap', 'r')">{{ $t('capacitiesLabel') }}</sidenav-router-link>
        </sidenav-menu>
      <sidenav-router-link icon="fas fa-phone" to="/calls" :exact="true">{{ $t('callsLabel') }}</sidenav-router-link>
      
<sidenav-header class="small font-weight-semibold">{{ $t('movements_Label') }}</sidenav-header>
<sidenav-divider class="mb-0 mt-0"></sidenav-divider>
      <!-- Dashboards -->
      <sidenav-menu icon="fas fa-book" :active="isMenuActive('/btc')" :open="isMenuOpen('/btc')">
        <template slot="link-text">{{ $t('logbookLabel') }}</template>
        <sidenav-router-link class="ml-0" to="/btc/btc" :exact="true">{{ $t('logbookLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/btc/clst" :exact="true">{{ $t('shiftClosureLabel') }}</sidenav-router-link>


        <!-- <sidenav-router-link class="ml-0" to="/btc/prd" :exact="true">Produccion</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/btc/qual" :exact="true">Calidad</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/btc/mnt" :exact="true">Mantenimiento</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/btc/gral" :exact="true">General</sidenav-router-link> -->

        <sidenav-router-link class="ml-0" v-for="item in binnacle" :to="`/btc/${item.code}`" :exact="true">{{item.text}}</sidenav-router-link>

      </sidenav-menu>

      <sidenav-menu icon="ion ion-md-contract" :active="isMenuActive('/drum')" :open="isMenuOpen('/drum')">
        <template slot="link-text">{{ $t('DrumLabel') }}</template>
        <!-- <sidenav-router-link class="ml-0" to="/drum/searchop" :exact="true">Detalle Orden de Producción</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/drum/detailorders" :exact="true">Ver Orden de Producción</sidenav-router-link>
        <sidenav-router-link class="ml-0" v-if="check_access('drump_production', 'r')"  to="/drum/reprint_batches" :exact="true">Reimpresion de Lotes</sidenav-router-link> -->
        <sidenav-router-link class="ml-0" v-if="check_access('drump_production', 'r')"  to="/drum/showops" :exact="true">{{ $t('viewOpenOrdersLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" v-if="check_access('drump_production', 'r')"  to="/drum/tacproduction" :exact="true">{{ $t('productionTACLabel') }}</sidenav-router-link>
        <!-- <sidenav-router-link class="ml-0" v-if="check_access('drump_production', 'r')"  to="/drum/productionorders" :exact="true">Tambor de Producción</sidenav-router-link> -->
        <sidenav-router-link class="ml-0" v-if="check_access('drump_commercial', 'r')" to="/drum/orders" :exact="true">{{ $t('orderDrumLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" v-if="check_access('drump_purchase', 'r')"  to="/drum/purchases" :exact="true">{{ $t('purchasingDrumLabel') }}</sidenav-router-link>
      </sidenav-menu>

      <!-- Layouts -->
      <sidenav-menu icon="fas fa-sitemap" :active="isMenuActive('/pp')" :open="isMenuOpen('/pp')">
        <template slot="link-text">{{ $t('planningModuleLabel') }}</template>
        <sidenav-router-link  class="ml-0"  to="/ppl/demand" :exact="true">{{ $t('demandPlannerLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" v-if="check_access('planning_online', 'r')" to="/ppl/invonl" :exact="true">{{ $t('onlineInventoryLabel') }}</sidenav-router-link>
        <sidenav-router-link  class="ml-0"  to="/ppl/oppp" :exact="true">{{ $t('resourceLoadingLabel') }}</sidenav-router-link>
        <sidenav-router-link  class="ml-0"  to="/ppl/pords" :exact="true">{{ $t('ordersPlanning') }}</sidenav-router-link>
        <sidenav-router-link  class="ml-0"  to="/ppl/grcapacity" :exact="true">{{ $t('loadLabel') }}</sidenav-router-link>
      </sidenav-menu>

      <sidenav-menu icon="fas fa-cogs" :active="isMenuActive('/mnt')" :open="isMenuOpen('/mnt')">
        <template slot="link-text">{{ $t('maintenanceModuleLabel') }} </template>
        <sidenav-router-link class="ml-0" to="/mnt/kanban" :exact="true">{{ $t('generalDashboardLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/mnt/newrq" :exact="true">{{ $t('pendingApprovalsLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/mnt/rqactives" :exact="true">{{ $t('activeRequestsLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/mnt/otact" :exact="true">{{ $t('activeWorkOrdersLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/mnt/otce" :exact="true">{{ $t('pendingWorkOrdersLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/mnt/auth" :exact="true">{{ $t('pendingApprovalsLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/mnt/register" :exact="true">{{ $t('informationLoggingLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/mnt/invonl" :exact="true">{{ $t('onlineInventoryLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/mnt/purch" :exact="true">{{ $t('pendingApprovalsLabel') }}</sidenav-router-link>
     </sidenav-menu>

      <sidenav-menu icon="fas fa-cash-register" :active="isMenuActive('/comm')" :open="isMenuOpen('/comm')">
        <template slot="link-text">{{ $t('commercialLabel') }}</template>
        <sidenav-router-link class="ml-0" v-if="check_access('tmb_visual_comm', 'r')" to="/comm/drump" :exact="true">{{ $t('commercialDrumLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" v-if="check_access('cot_comm', 'r')" to="/comm/cot/list" :exact="true">{{ $t('quotationListingLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" v-if="check_access('new_order_comm', 'r')" to="/comm/newrq/local/0" :exact="true">{{ $t('newOrderLabel') }}</sidenav-router-link>
        <!-- <sidenav-router-link class="ml-0" v-if="check_access('planning_resources', 'r')" to="/comm/newrq/international/0" :exact="true">Pedido Internacional</sidenav-router-link> -->
        <sidenav-router-link class="ml-0" v-if="check_access('new_quot_comm', 'r')" to="/comm/cot/local/0" :exact="true">{{ $t('newQuotationLabel') }}</sidenav-router-link>
        <!-- <sidenav-router-link class="ml-0" v-if="check_access('planning_resources', 'r')" to="/comm/cotintnal/0" :exact="true">Cotizacion Internacional</sidenav-router-link> -->
        <sidenav-router-link class="ml-0" v-if="check_access('liberar_comm', 'r')" to="/comm/rel" :exact="true">{{ $t('releaseOrdersLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" v-if="check_access('appr_comm', 'r')" to="/comm/appr" :exact="true">{{ $t('approveOrdersLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" v-if="check_access('bill_comm', 'r')" to="/comm/cart" :exact="true">{{ $t('portfolioLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" v-if="check_access('bill_comm', 'r')" to="/comm/newpo/0" :exact="true">Pedido Programado</sidenav-router-link>
     </sidenav-menu>

      <sidenav-menu icon="fas fa-truck" :active="isMenuActive('/deliv')" :open="isMenuOpen('/deliv')">
        <template slot="link-text">{{ $t('ShipmentsLabel') }}</template>
        <sidenav-router-link class="ml-0" to="/deliv/prep" :exact="true">{{ $t('prepareShipmentLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/deliv/conf" :exact="true">{{ $t('confirmShipmentLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/deliv/inv" :exact="true">{{ $t('viewShipmentsLabel') }}</sidenav-router-link>
     </sidenav-menu>

      <sidenav-menu icon="fas fa-boxes" :active="isMenuActive('/invent')" :open="isMenuOpen('/invent')">
        <template slot="link-text">{{ $t('InventoryLabel') }}</template>
        <sidenav-router-link class="ml-0" to="/invent/invgral" :exact="true">{{ $t('generalInventoryLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/invent/detubq" :exact="true">{{ $t('locationDetailsLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/invent/movs" :exact="true">{{ $t('movementsLabel') }}</sidenav-router-link>
        <!-- <sidenav-router-link class="ml-0" to="/invent/details" :exact="true">Detalle de Movimientos</sidenav-router-link> -->
     </sidenav-menu>

    <sidenav-menu icon="fas fa-calculator" v-if="check_access('costs_opr', 'r')" :active="isMenuActive('/cst')" :open="isMenuOpen('/cst')">
        <template slot="link-text">Costos</template>
        <sidenav-router-link class="ml-0" to="/cst/prb" :exact="true">Costo Producto</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/cst/sbrp" :exact="true">Subreparto</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/cst/bgt" :exact="true">Presupuesto Contable</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/cst/cmr" :exact="true">Presupuesto Comercial</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/cst/prd" :exact="true">Presupuesto Produccion</sidenav-router-link>
        <!-- <sidenav-router-link class="ml-0" to="/invent/details" :exact="true">Detalle de Movimientos</sidenav-router-link> -->
     </sidenav-menu>

      <!-- Layouts -->
      <sidenav-header class="small font-weight-semibold">{{ $t('reports_Label') }}</sidenav-header>
      <sidenav-divider class="mb-0 mt-0"></sidenav-divider>

      <sidenav-menu  icon="ion ion-md-analytics" :active="isMenuActive('/ts')" :open="isMenuOpen('/reprt')">
        <template slot="link-text">{{ $t('seriesAlarmsLabel') }}</template>
        <sidenav-router-link to="/ts/timeseries" :exact="true">{{ $t('seriesQueryLabel') }}</sidenav-router-link>
        <sidenav-router-link to="/ts/rttimeseries" :exact="true">{{ $t('realTimeSeriesLabel') }}</sidenav-router-link>
        <sidenav-router-link to="/ts/alarms" :exact="true">{{ $t('alarmVisualizationLabel') }}</sidenav-router-link>
      </sidenav-menu>
      <sidenav-menu icon="fas fa-chart-bar" :active="isMenuActive('/reprt')" :open="isMenuOpen('/reprt')">
        <template slot="link-text">{{ $t('shopFloorControlLabel') }}</template>
        <!-- <sidenav-router-link class="ml-0" to="/reprt/oeeworkgroup" :exact="true">OEE Grupos</sidenav-router-link> -->
        <!-- <sidenav-router-link class="ml-0" to="/reprt/oeeworkstationdetails" :exact="true">OEE Centros de Trabajo</sidenav-router-link> -->
        <sidenav-router-link class="ml-0" to="/reprt/stopcodepergroup" :exact="true">T{{ $t('downtimeByGroupsLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/reprt/resumenoeedetal" :exact="true">{{ $t('detailedOEELabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/reprt/stopcodeperworkstation" :exact="true">{{ $t('generalSummaryLabel') }}</sidenav-router-link>
        
        
        <!-- <sidenav-router-link class="ml-0" to="/reprt/general" :exact="true">Totales Generales</sidenav-router-link> -->
        <sidenav-router-link class="ml-0" to="/reprt/oee_planta" :exact="true">{{ $t('oeeTrendsLabel') }}</sidenav-router-link>
        <!-- <sidenav-router-link class="ml-0" to="/reprt/workstationdetails" :exact="true">Detalle Centros de Trabajo</sidenav-router-link> -->
        <!-- <sidenav-router-link class="ml-0" to="/reprt/orderproduction" :exact="true">Ordenes de Producción</sidenav-router-link> -->
        <sidenav-router-link class="ml-0" to="/reprt/stopcodeperworkstation" :exact="true">{{ $t('productionSummaryLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/reprt/products" :exact="true">{{ $t('productSearchLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/reprt/productiontime" :exact="true">{{ $t('timeRangeProductsLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/reprt/cmp" :exact="true">C{{ $t('materialConsumptionLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/reprt/reportproduction" :exact="true">{{ $t('reportsLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/reprt/detailworkstation" :exact="true">{{ $t('productionSummaryLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/reprt/calls" :exact="true">{{ $t('callsLabel') }}</sidenav-router-link>
      </sidenav-menu>

      <sidenav-menu icon="fas fa-wrench" :active="isMenuActive('/mttoreport')" :open="isMenuOpen('/mttoreport')">
        <template slot="link-text">{{ $t('maintenanceModuleLabel') }}</template>
        <sidenav-router-link class="ml-0" to="/mttoreport/disp" :exact="true">{{ $t('workCenterAvailabilityLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/mttoreport/mtbf" :exact="true">{{ $t('mtbfByWorkCenterLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/mttoreport/mttr" :exact="true">M{{ $t('mttrByWorkCenterLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/mttoreport/mttrmtbf" :exact="true">{{ $t('tbfAndMttrByWorkCenterLabel') }}</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/mttoreport/mttrmtbfmonth" :exact="true">M{{ $t('mtbfAndMttrByMonthLabel') }}</sidenav-router-link>
      </sidenav-menu>


      <sidenav-header class="small font-weight-semibold">{{ $t('configurationLabel') }}</sidenav-header>

      <sidenav-divider class="mb-0 mt-0"></sidenav-divider>
        <sidenav-menu icon="fas fa-barcode" :active="isMenuActive('/cfpr')" :open="isMenuOpen('/cfpr')">
          <template slot="link-text">{{ $t('productsLabel') }}</template>
          <!-- <sidenav-router-link v-if="check_access('products_route', 'r')" to="/cfpr/routes" :exact="true">Rutas de Productos</sidenav-router-link> -->
          <sidenav-router-link v-if="check_access('products_fammily', 'r')" to="/cfpr/families/prd" :exact="true">{{ $t('productFamilyLabel') }}</sidenav-router-link>
          <sidenav-router-link v-if="check_access('products_fammily', 'r')" to="/cfpr/lines/prd" :exact="true">{{ $t('productLinesLabel') }}</sidenav-router-link>
          <sidenav-router-link v-if="check_access('products_products', 'r')" to="/cfpr/product/prd" :exact="true">{{ $t('productsLabel') }}</sidenav-router-link>
          <sidenav-router-link to="/cfwh/warehouse" :exact="true">{{ $t('warehousesLabel') }}</sidenav-router-link>
          <sidenav-router-link to="/cfwh/ubc" :exact="true">{{ $t('locationsLabel') }}</sidenav-router-link>
      </sidenav-menu>

      <sidenav-menu icon="ion ion-ios-business" :active="isMenuActive('/cfws')" :open="isMenuOpen('/cfws')">
        <template slot="link-text">Operaciones</template>
        <sidenav-router-link v-if="check_access('operations_tools', 'r')" to="/cfws/tools" :exact="true">{{ $t('toolingsLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('operations_plants', 'r')" to="/cfws/plants" :exact="true">{{ $t('plantsLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('operations_workstation_groups', 'r')" to="/cfws/wsgroup" :exact="true">{{ $t('workCenterGroupsLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('operations_workstation', 'r')" to="/cfws/workstations" :exact="true">{{ $t('workCentersLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('operations_process', 'r')" class="ml-0" to="/cfsw/process" :exact="true">{{ $t('processDefinitionLabel') }}</sidenav-router-link>
      </sidenav-menu>

      <sidenav-menu icon="ion ion-ios-albums" :active="isMenuActive('/cfcp')" :open="isMenuOpen('/cfcp')">
        <template slot="link-text">{{ $t('shopFloorControlLabel') }}</template>
        <sidenav-router-link v-if="check_access('floor_stopcode', 'r')" to="/rt/stopcode" :exact="true">{{ $t('downtimeCodesLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('floor_root', 'r')" to="/rt/rootcause" :exact="true">{{ $t('rootCauseCodesLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('floor_scrap', 'r')" to="/rt/scrap" :exact="true">{{ $t('defectCodesLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('floor_scrap', 'r')" to="/rt/alarms" :exact="true">{{ $t('alarmsLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('floor_scrap', 'r')" to="/rt/alarmcount" :exact="true">{{ $t('counterCodesLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('floor_tags', 'r')" to="/rt/callers" :exact="true">{{ $t('callsLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('floor_tags', 'r')" to="/rt/configtags" :exact="true">{{ $t('tagConfigurationLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('floor_tags', 'r')" to="/rt/oee" :exact="true">{{ $t('oeeVariablesLabel') }}</sidenav-router-link>
     </sidenav-menu>

      <sidenav-menu icon="ion ion-md-options" :active="isMenuActive('/cfpl')" :open="isMenuOpen('/cfpl')">
        <template slot="link-text">{{ $t('capacitiesLabel') }}</template>
        <sidenav-router-link v-if="check_access('capacity_holidays', 'r')" to="/cfpl/holidays" :exact="true">{{ $t('holidaysLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('capacity_capacity', 'r')" to="/cfpl/capacity" :exact="true">{{ $t('capacitiesLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('capacity_calendar', 'r')" to="/cfpl/calendar" :exact="true">{{ $t('calendarLabel') }}</sidenav-router-link>
      </sidenav-menu>


      <sidenav-menu icon="fas fa-warehouse" :active="isMenuActive('/cfwh')" :open="isMenuOpen('/cfwh')">
        <template slot="link-text">{{ $t('storagesLabel') }}</template>
      </sidenav-menu>

      <sidenav-menu icon="fas fa-cog" :active="isMenuActive('/cfmnt')" :open="isMenuOpen('/cfmnt')">
        <template slot="link-text">{{ $t('maintenanceLabel') }}</template>
        <sidenav-router-link to="/cfmnt/groups/mnt" :exact="true">Grupo de Partes</sidenav-router-link>
        <sidenav-router-link to="/cfmnt/product/mnt" :exact="true">Registro de Partes</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/cfmnt/ra" :exact="true">Registro de Activos</sidenav-router-link>
        <sidenav-router-link class="ml-0" to="/cfmnt/ctg" :exact="true">Categorías</sidenav-router-link>
     </sidenav-menu>


    <sidenav-menu icon="fas fa-calculator"  v-if="check_access('costs_opr', 'r')" :active="isMenuActive('/cst')" :open="isMenuOpen('/cst')">
        <template slot="link-text" >Costos</template>
        <sidenav-router-link class="ml-0" v-if="check_access('costs_opr', 'r')" to="/cst/syf" :exact="true">Sociedades Financieras</sidenav-router-link>
        <sidenav-router-link class="ml-0" v-if="check_access('costs_opr', 'r')" to="/cst/clcc" :exact="true">Clase de CC</sidenav-router-link>
        <sidenav-router-link class="ml-0" v-if="check_access('costs_opr', 'r')" to="/cst/tdc" :exact="true">Tipos de Cuenta</sidenav-router-link>
        <sidenav-router-link class="ml-0" v-if="check_access('costs_opr', 'r')" to="/cst/puc" :exact="true">Plan de Cuentas</sidenav-router-link>
        <sidenav-router-link class="ml-0" v-if="check_access('costs_opr', 'r')" to="/cst/mcc" :exact="true">Maestros de Centros de Costos</sidenav-router-link>
        <sidenav-router-link class="ml-0" v-if="check_access('costs_opr', 'r')" to="/cst/ca" :exact="true">Clase de Actividad</sidenav-router-link>
        <sidenav-router-link class="ml-0" v-if="check_access('costs_opr', 'r')" to="/cst/ep" :exact="true">Esquema de Partición</sidenav-router-link>
        <!-- <sidenav-router-link class="ml-0" to="/cst/bgt" :exact="true">Presupuesto</sidenav-router-link> -->
        <!-- <sidenav-router-link class="ml-0" to="/invent/details" :exact="true">Detalle de Movimientos</sidenav-router-link> -->
     </sidenav-menu>

      <sidenav-menu icon="ion ion-ios-filing" :active="isMenuActive('/cfgn')" :open="isMenuOpen('/cfgn')">
        <template slot="link-text">{{ $t('generalLabel') }}</template>
        <sidenav-router-link v-if="check_access('general_customer', 'r')" to="/cfgn/poli" :exact="true">{{ $t('commercialModuleLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('general_customer', 'r')" to="/cfgn/customer" :exact="true">{{ $t('customersLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('general_providers', 'r')" to="/cfgn/supplier" :exact="true">{{ $t('suppliersLabel') }}</sidenav-router-link>
      </sidenav-menu>

      <sidenav-header class="small font-weight-semibold">{{ $t('platformLabel') }}</sidenav-header>
      <sidenav-divider class="mb-0 mt-0"></sidenav-divider>
      <sidenav-menu icon="fas fa-object-group" :active="isMenuActive('/bcf')" :open="isMenuOpen('/bcf')">
        <template slot="link-text">{{ $t('administrationLabel') }}</template>
        <sidenav-router-link v-if="check_access('admin_um', 'r')" to="/bcf/um" :exact="true">{{ $t('unitsOfMeasureLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('floor_stopcode', 'r')" to="/bcf/forms" :exact="true">{{ $t('configForms') }}</sidenav-router-link>
        <!-- <sidenav-router-link to="/bcf/um" :exact="true">Transacciones</sidenav-router-link> -->
        <sidenav-router-link v-if="check_access('admin_tools_type', 'r')" to="/bcf/tooltype" :exact="true">{{ $t('toolingTypesLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('admin_workstation_type', 'r')" to="/bcf/workstation_types" :exact="true">{{ $t('workCenterTypesLabel') }}</sidenav-router-link>
        <!-- <sidenav-router-link to="/bcf/products_types" :exact="true">Tipos de Productos</sidenav-router-link> -->
        <sidenav-router-link v-if="check_access('admin_document_status', 'r')" to="/bcf/documentstatus" :exact="true">{{ $t('documentStatusLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('admin_documents_types', 'r')" to="/bcf/documents_types" :exact="true"> {{ $t('documentTypesLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('admin_documents_types', 'r')" to="/bcf/btctpa" :exact="true"> {{ $t('activityLogTypesLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('admin_documents_types', 'r')" to="/bcf/mov_types" :exact="true"> {{ $t('movementTypesLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('admin_funcitonal_area', 'r')" to="/bcf/areas" :exact="true">{{ $t('functionalAreasLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('admin_personal', 'r')" to="/bcf/operator" :exact="true">{{ $t('personnelLabel') }}</sidenav-router-link>
      </sidenav-menu>
      <sidenav-menu icon="fas fa-users" :active="isMenuActive('/users')" :open="isMenuOpen('/users')">
        <template slot="link-text">{{ $t('usersLabel') }}</template>
        <sidenav-router-link v-if="check_access('users', 'r')" to="/users/users_list" :exact="true">{{ $t('userListLabel') }}</sidenav-router-link>
        <!-- <sidenav-router-link to="/users/users_view" :exact="true">Perfil Usuario</sidenav-router-link> -->
        <sidenav-router-link v-if="check_access('users_edit', 'r')" to="/users/users_edit" :exact="true">{{ $t('createUserLabel') }}</sidenav-router-link>
      </sidenav-menu>
        <sidenav-menu icon="fas fa-info-circle" :active="isMenuActive('/sys')" :open="isMenuOpen('/sys')">
        <template slot="link-text">{{ $t('informationSyncBoxModuleLabel') }}</template>
        <sidenav-router-link v-if="check_access('admin_services', 'r')" to="/sys/task_scheduler" :exact="true">{{ $t('scheduledTasksLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('info_check', 'r')" to="/sys/devices" :exact="true">{{ $t('diagnosisLabel') }}</sidenav-router-link>
        <sidenav-router-link v-if="check_access('info_licence', 'r')" to="/sys/infolicense" :exact="true">{{ $t('licenseLabel') }}</sidenav-router-link>
      </sidenav-menu>

    </div>
  </sidenav>
</template>

<script>
import { Sidenav, SidenavLink, SidenavRouterLink, SidenavMenu, SidenavHeader, SidenavBlock, SidenavDivider } from '@/vendor/libs/sidenav'
import { infousers } from '@/components/i40/js/users'
import { infosysconfig } from "@/components/i40/js/sysconfig";

export default {
  name: 'app-layout-sidenav',
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },

  computed: {
    curClasses () {
      let bg = this.layoutSidenavBg

      if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return `bg-${bg} ` + (
        this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0'
      )
    }
  },

  data: () => ({
    binnacle: []
  }),

  methods: {
    isMenuActive (url) {
      return this.$route.path.indexOf(url) === 0
    },

    isMenuOpen (url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
    },

    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    },

    check_access (control, action) {
      return infousers.check_access(control, action)
    }
  },

  beforeCreate(){
    infosysconfig.functionalarea([],"select").then(data => {
        this.binnacle = []
        if(data.data != ""){
            for (let index = 0; index < data.data.length; index++) {
              const element = data.data[index];
              if(element.binnacle_active)
              this.binnacle.push({text:element.description, code:element.binnacle_code}) 
            }
        }
    })
  }

  
}
</script>
