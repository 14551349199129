<template>
  <b-navbar-nav class="align-items-lg-center">
    <b-nav-item-dropdown icon="ion ion-ios-albums">
      <template slot="button-content">
        <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
          <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{ $t('textOperations') }}</span>
        </span>
      </template>
      <b-dd-item v-if="check_access('operations_plants', 'r')" to="/IoT/plants" :exact="true">{{ $t('plantsLabel') }}</b-dd-item>
      <b-dd-item v-if="check_access('operations_workstation_groups', 'r')" to="/IoT/wsgroup" :exact="true">{{ $t('workstationGroupsLabel') }}</b-dd-item>
      <b-dd-item v-if="check_access('operations_workstation', 'r')" to="/IoT/workstations" :exact="true">{{ $t('workCenterLabel') }}</b-dd-item>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>

import { infousers } from '@/components/i40/js/users';
export default {
  name: 'NewFrontv3IoTLayout',

  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    

    check_access(control, action) {
      return infousers.check_access(control, action)
      // return true
    },

  },
};
</script>