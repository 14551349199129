const RealtimeRoutes = [
    {
        path: 'rt',
        // component: () => import('@/components/sbx/globals/realtime_view_v2')
        component: () => import('@/components/sbx/realtime/dashboards/oee/OEEDashboardView.vue')
    },
    {
        path: 'rt/:codefloor/:idfloor',
        component: () => import('@/components/sbx/globals/realtime_view_v2')        
    },
    {
        path: 'rt/:codefloor/:idfloor/:codegroup/:idgroup',
        component: () => import('@/components/sbx/globals/realtime_view_v2')
    },
    {
        path: 'rt/:codefloor/:idfloor/:codegroup/:idgroup/:wscode',
        // component: () => import('@/components/sbx/globals/realtime_view_v2')
        component: () => import('@/components/sbx/realtime/dashboards/oee/OEEDashboardView.vue')
    },
    {
        path: 'rt/timeseries',
        // component: () => import('@/components/sbx/timeseries/time_series')
        component: () => import('@/components/sbx/timeseries/query_time_series/QueryTimeSeriesView')
    },
    {
        path: 'rt/rttimeseries',
        component: () => import('@/components/sbx/timeseries/rttimeseries')
    },
    {
        path: 'rt/alarms',
        component: () => import('@/components/sbx/timeseries/alarms')
    },
    {
        path: 'rt/calls',
        component: () => import('@/components/sbx/globals/calls')
    },
    {
        path: 'rt/order_programming',
        component: () => import('@/components/sbx/planning/order_programming')
    },
    /////CONTROL DE PISO

    {
        path: 'rt/configtags',
        component: () => import('@/components/sbx/config_tags')
    },
    {
        path: 'rt/stopcode',
        component: () => import('@/components/sbx/floor_control/stop_code')
    },
    {
        path: 'rt/rootcause',
        component: () => import('@/components/sbx/floor_control/code_root_cause')
    },
    {
        path: 'rt/scrap',
        component: () => import('@/components/sbx/floor_control/scrap')
    },
    {
        path: 'rt/alarms',
        component: () => import('@/components/sbx/floor_control/alarms')
    },
    {
        path: 'rt/oee',
        component: () => import('@/components/sbx/floor_control/oee')
    },
    {
        path: 'rt/alarmcount',
        component: () => import('@/components/sbx/floor_control/counters_programmer')
    },
    {
        path: 'rt/callers',
        component: () => import('@/components/sbx/floor_control/callers')
    },


    /////CONTROL DE PISO

    {
        path: 'rt/edittime',
        component: () => import('@/components/sbx/realtime/modificartiempos')
    },
    {
        path: 'rt/apptime',
        component: () => import('@/components/sbx/realtime/apptiempos')
    },
    {
        path: 'alarms',
        component: () => import('@/components/sbx/machine_alarms')
    },

]

export {
    RealtimeRoutes
}