<template>
  <b-navbar-nav class="align-items-lg-center">
    <b-nav-item-dropdown icon="ion ion-ios-albums">
      <template slot="button-content">
        <span
          class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
        >
          <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
            $t("analysisLabel")
          }}</span>
        </span>
      </template>
      <b-dd-item class="ml-0" to="/cst/prb" :exact="true">{{
        $t("productCostLabel")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/cst/sbrp" :exact="true">{{
        $t("subcontractingLabel")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/cst/bgt" :exact="true">{{
        $t("accountingBudgetLabel")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/cst/cmr" :exact="true">{{
        $t("commercialBudgetLabel")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/cst/prd" :exact="true"
        >P{{ $t("productionBudgetLabel") }}</b-dd-item
      >
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
