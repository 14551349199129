const MaintenanceRoutes = [
  {
    path: "mnt/",
    component: () => import("@/components/sbx/maintenance/mto_start.vue"),
  },
  {
    path: "mnt/kanban",
    component: () => import("@/components/sbx/maintenance/kanban"),
  },
  {
    path: "mnt/indicators",
    component: () => import("@/components/sbx/maintenance/indicators/IndicatorsView.vue"),
  },
  {
    path: "mnt/operators",
    component: () => import("@/components/sbx/maintenance/operators-tasks-overview/OperatorsTaskOverView.vue"),
  },
  {
    path: "mnt/my-tasks",
    component: () => import("@/components/sbx/maintenance/operator-tasks/OperatorTaksView.vue"),
  },
  {
    path: "mnt/ot-assignment",
    component: () => import("@/components/sbx/maintenance/task-assignments/TaskViewV2.vue"),
  },
  {
    path: "mnt/newrq",
    component: () => import("@/components/sbx/maintenance/solicitudes"),
  },
  {
    path: "mnt/auth",
    component: () => import("@/components/sbx/maintenance/authorization"),
  },
  {
    path: "mnt/rqactives",
    component: () => import("@/components/sbx/maintenance/requests_actives"),
  },
  {
    path: "mnt/register",
    component: () => import("@/components/sbx/maintenance/registro_info"),
  },
  {
    path: "mnt/otact",
    component: () => import("@/components/sbx/maintenance/ot_actives"),
  },
  {
    path: "mnt/otce",
    component: () => import("@/components/sbx/maintenance/ot_to_finish"),
  },
  {
    path: "mnt/invonl",
    component: () => import("@/components/sbx/maintenance/inv_linea"),
  },
  {
    path: "mnt/purch",
    component: () => import("@/components/sbx/maintenance/compras"),
  },
  {
    path: "mnt/scheduled",
    component: () =>
      import(
        "@/components/sbx/maintenance/scheduled-maintenance/ScheduledMaintenanceView.vue"
      ),
  },
  {
    path: "mnt/businesses",
    component: () =>
      import("@/components/sbx/maintenance/businesses/BusinessesView.vue"),
  },
  {
    path: "mnt/materials",
    component: () =>
      import("@/components/sbx/maintenance/ot-materials/OTMaterials.vue"),
  },
  {
    path: "mnt/ot-board",
    component: () => import("@/components/sbx/maintenance/ots-board/OTsBoard.vue"),
  }
];

export { MaintenanceRoutes };
