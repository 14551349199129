<template>
  <b-navbar-nav class="align-items-lg-center">
    <b-nav-item-dropdown icon="ion ion-ios-albums">
      <template slot="button-content">
        <span
          class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
        >
          <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
            $t("generalLabel")
          }}</span>
        </span>
      </template>
      <b-dd-item
        v-if="check_access('admin_um', 'r')"
        to="/bcf/um"
        :exact="true"
        >{{ $t("unitsOfMeasureLabel") }}</b-dd-item
      >
      <b-dd-item
        v-if="check_access('floor_stopcode', 'r')"
        to="/bcf/forms"
        :exact="true"
        >{{ $t("general.formsAdmin") }}</b-dd-item
      >
      <!-- <b-dd-item to="/bcf/um" :exact="true">Transacciones</b-dd-item> -->
      <b-dd-item
        v-if="check_access('admin_tools_type', 'r')"
        to="/bcf/tooltype"
        :exact="true"
        >{{ $t("toolingTypesLabel") }}</b-dd-item
      >
      <b-dd-item
        v-if="check_access('admin_workstation_type', 'r')"
        to="/bcf/workstation_types"
        :exact="true"
      >
        {{ $t("workCenterTypesLabel") }}</b-dd-item
      >
      <!-- <b-dd-item to="/bcf/products_types" :exact="true">Tipos de Productos</b-dd-item> -->
      <b-dd-item
        v-if="check_access('admin_document_status', 'r')"
        to="/bcf/documentstatus"
        :exact="true"
        >{{ $t("documentStatusLabel") }}</b-dd-item
      >
      <b-dd-item
        v-if="check_access('admin_documents_types', 'r')"
        to="/bcf/documents_types"
        :exact="true"
      >
        {{ $t("general.documentsType") }}</b-dd-item
      >
      <b-dd-item
        v-if="check_access('admin_documents_types', 'r')"
        to="/bcf/btctpa"
        :exact="true"
      >
        {{ $t("activityLogTypesLabel") }}</b-dd-item
      >
      <b-dd-item
        v-if="check_access('admin_documents_types', 'r')"
        to="/bcf/mov_types"
        :exact="true"
      >
        {{ $t("movementTypesLabel") }}</b-dd-item
      >
      <b-dd-item
        v-if="check_access('admin_funcitonal_area', 'r')"
        to="/bcf/areas"
        :exact="true"
      >
        {{ $t("functionalAreasLabel") }}</b-dd-item
      >
      <b-dd-item
        v-if="check_access('admin_personal', 'r')"
        to="/bcf/operator"
        :exact="true"
      >
        {{ $t("personnelLabel") }}</b-dd-item
      >
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
export default {
  props: {
    check_access: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
