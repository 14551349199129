/**
 * @file I18n configuration file
 * @copyright Daniel Salazar 2023
 * @mail <dsalazar@smgsoftware.com>
 */

import Vue from "vue";
import VueI18n from "vue-i18n";
import enMessages from '../../../locales/en.json'
import esMessages from '../../../locales/es.json'
import mantEs from '@/locales/maintenance/mantEs.json'
import mantEn from '@/locales/maintenance/mantEn.json'

import plannEs from '@/locales/planning/plannEs.json'
import plannEn from '@/locales/planning/plannEn.json'


Vue.use(VueI18n)

const messages = {
    en: {
        ...enMessages,
        ...mantEn,
        ...plannEn
    },
    es: {
        ...esMessages,
        ...mantEs,
        ...plannEs
    },
}


export const i18n = new VueI18n({
    locale: localStorage.getItem("selectedLanguage") || "es",
    messages,
})



