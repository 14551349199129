
const IOTRoutes = [
    {
        path: '/IoT',
        component: () => import('@/components/sbx/iot/generalDashboard/GeneralDashboardView.vue')
    },
    {
        path: 'IoT/workstations',
        component: () => import('@//components/sbx/master/workstation')
    },
    {
        path: 'IoT/plants',
        component: () => import('@//components/sbx/master/workstation_plant')
    },
    {
        path: 'IoT/wsgroup',
        component: () => import('@//components/sbx/master/workstation_group')
    },
    {
        path: '/IoT/timeseries',
        component: () => import("@/components/sbx/iot/informationRealTime/realTimeSeries/TimeSeriesView.vue")
    },
    {
        path: '/IoT/qytimeseries',
        component: () => import('@/components/sbx/iot/informationRealTime/queryTimeSeries/QueryTimeSeriesView.vue')
    },
    {
        path: 'IoT/workstation_types',
        component: () => import('@/components/sbx/sysconfig/workstation_types')
    },
    {
        path: 'IoT/databases',
        component: () => import('@/components/sbx/iot/config/databases')
    },
]

export {
    IOTRoutes 
}