<template>
  <b-navbar-nav icon="fas fa-users">
    <b-nav-item-dropdown icon="ion ion-ios-albums">
      <template slot="button-content">
        <span
          class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
        >
          <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{ $t("usersLabel") }}</span>
        </span>
      </template>
      <b-dd-item
        v-if="check_access('users', 'r')"
        to="/users/users_list"
        :exact="true"
        >{{ $t("userListLabel") }}</b-dd-item
      >
      <b-dd-item
        v-if="check_access('users_edit', 'r')"
        to="/users/users_edit"
        :exact="true"
        >{{ $t("createUserLabel") }}</b-dd-item
      >
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
export default {
  props: {
    check_access: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
