<template>
  <b-navbar-nav class="align-items-lg-center">
    <b-nav-item-dropdown icon="ion ion-ios-albums">
      <template slot="button-content">
        <span
          class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
        >
          <!-- <img :src="require('@/vendor/avatars/default.png')" alt class="d-block ui-w-30 rounded-circle"> -->
          <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
            $t("reportsLabel")
          }}</span>
        </span>
      </template>
      <!-- <b-dd-item class="ml-0" to="/btc/btc" :exact="true">Bitácora</b-dd-item> -->
      <b-dd-item class="ml-0" to="/reprt/stopcodepergroup" :exact="true">{{
        $t("downtimeByGroupsLabel")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/reprt/resumenoeedetal" :exact="true">{{
        $t("detailedOEELabel")
      }}</b-dd-item>
      <b-dd-item
        class="ml-0"
        to="/reprt/stopcodeperworkstation"
        :exact="true"
        >{{ $t("generalSummaryLabel") }}</b-dd-item
      >
      <!-- <b-dd-item class="ml-0" to="/reprt/general" :exact="true">Totales Generales</b-dd-item> -->
      <b-dd-item class="ml-0" to="/reprt/oee_planta" :exact="true">{{
        $t("oeeTrendsLabel")
      }}</b-dd-item>
      <!-- <b-dd-item class="ml-0" to="/reprt/workstationdetails" :exact="true">Detalle Centros de Trabajo</b-dd-item> -->
      <!-- <b-dd-item class="ml-0" to="/reprt/orderproduction" :exact="true">Ordenes de Producción</b-dd-item> -->
      <b-dd-item class="ml-0" to="/reprt/products" :exact="true">{{
        $t("productSearchLabel")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/reprt/productiontime" :exact="true">{{
        $t("timeRangeProductsLabel")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/reprt/cmp" :exact="true">{{
        $t("materialConsumptionLabel")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/reprt/reportproduction" :exact="true">{{
        $t("reportsLabel")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/reprt/calls" :exact="true">{{
        $t("callsLabel")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/reprt/preshift" :exact="true">{{
        $t("preshiftLabel")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/reprt/detailworkstation" :exact="true">{{
        $t("productionSummaryLabel")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/reprt/executiveendofshift" :exact="true">{{
        $t("reports.linkExecutiveEndOfShift")
      }}</b-dd-item>

      <b-dd-divider></b-dd-divider>
      <!-- <template slot="link-text">Mantenimiento</template> -->
      <b-dd-item class="ml-0" to="/mttoreport/disp" :exact="true">{{
        $t("workCenterAvailabilityLabel")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/mttoreport/mtbf" :exact="true">{{
        $t("mtbfByWorkCenterLabel")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/mttoreport/mttr" :exact="true">{{
        $t("mttrByWorkCenterLabel")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/mttoreport/mttrmtbf" :exact="true">{{
        $t("tbfAndMttrByWorkCenterLabel")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/mttoreport/mttrmtbfmonth" :exact="true">{{
        $t("mtbfAndMttrByMonthLabel")
      }}</b-dd-item>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
