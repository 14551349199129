<template>
  <b-navbar-nav class="align-items-lg-center">
    <b-nav-item-dropdown icon="ion ion-ios-albums">
      <template slot="button-content">
        <span
          class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
        >
          <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
            $t("maintenanceModuleLabel")
          }}</span>
        </span>
      </template>
      <b-dd-item class="ml-0" to="/mnt/scheduled" :exact="true">{{
        $t("scheduledMaintenance")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/mnt/otact" :exact="true">{{
        $t("activeWorkOrdersLabel")
      }}</b-dd-item>
      <b-dd-item class="ml-0" to="/mnt/otce" :exact="true">{{
        $t("pendingWorkOrdersLabel")
      }}</b-dd-item>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
