<template>
  <b-navbar-nav class="align-items-lg-center">
    <b-nav-item-dropdown icon="ion ion-ios-albums">
      <template slot="button-content">
        <span
          class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
        >
          <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
            $t("reportsLabel")
          }}</span>
        </span>
      </template>
      <b-dd-item class="ml-0" to="/cst/cmv" :exact="true">CMV</b-dd-item>
      <b-dd-item class="ml-0" to="/cst/cecosact" :exact="true"
        >Informe CeCos por Actividad</b-dd-item
      >
      <b-dd-item class="ml-0" to="/cst/mdeu" :exact="true">{{
        $t("profitMarginLabel")
      }}</b-dd-item>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
