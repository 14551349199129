const BitacoraRoutes = [
    {
        path: 'btc/clst',
        component: () => import('@/components/sbx/traza/cierreturno')
    },
    {
        path: 'btc/btc',
        component: () => import('@/components/sbx/traza/bitacora')
    },
    {
        path: 'btc/det/:code',
        component: () => import('@/components/sbx/traza/bitacora_details')
    },

    {
        path: 'btc/:code',
        component: () => import('@/components/sbx/traza/bitacora3')
    },
]

export {
    BitacoraRoutes
}