<template>
  <b-navbar-nav class="align-items-lg-center">
    <b-nav-item-dropdown icon="ion ion-ios-albums">
      <template slot="button-content">
        <span
          class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
        >
          <!-- <img :src="require('@/vendor/avatars/default.png')" alt class="d-block ui-w-30 rounded-circle"> -->

          <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
            $t("shopFloorControlLabel")
          }}</span>
        </span>
      </template>
      <b-dd-item
        v-if="check_access('floor_stopcode', 'r')"
        to="/rt/stopcode"
        :exact="true"
        >{{ $t("downtimeCodesLabel") }}</b-dd-item
      >
      <b-dd-item
        v-if="check_access('floor_root', 'r')"
        to="/rt/rootcause"
        :exact="true"
        >{{ $t("rootCauseCodesLabel") }}</b-dd-item
      >
      <b-dd-item
        v-if="check_access('floor_scrap', 'r')"
        to="/rt/scrap"
        :exact="true"
        >{{ $t("defectCodesLabel") }}</b-dd-item
      >
      <b-dd-item
        v-if="check_access('floor_scrap', 'r')"
        to="/rt/alarms"
        :exact="true"
        >{{ $t("alarmsLabel") }}</b-dd-item
      >
      <b-dd-item
        v-if="check_access('floor_scrap', 'r')"
        to="/rt/alarmcount"
        :exact="true"
        >{{ $t("counterCodesLabel") }}</b-dd-item
      >
      <b-dd-item
        v-if="check_access('floor_tags', 'r')"
        to="/rt/callers"
        :exact="true"
        >{{ $t("callsLabel") }}</b-dd-item
      >
      <b-dd-item
        v-if="check_access('floor_tags', 'r')"
        to="/rt/configtags"
        :exact="true"
        >{{ $t("tagConfigurationLabel") }}</b-dd-item
      >
      <b-dd-item
        v-if="check_access('floor_tags', 'r')"
        to="/rt/oee"
        :exact="true"
        >{{ $t("oeeVariablesLabel") }}</b-dd-item
      >
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
export default {
  props: {
    check_access: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
