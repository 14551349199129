/* eslint no-trailing-spaces: "error" */
import axios from 'axios'
import { account } from "./globals"
import { InfoConfig,protocolo } from '@/vendor/sbx/conn';
let host =  InfoConfig.host()
// let host = '192.168.115.48'
let axiosConfig = {
    headers: {
        'Authorization': JSON.parse(localStorage.getItem('syncbox.cloud')) == null ? null : JSON.parse(localStorage.getItem('syncbox.cloud')).token
    }
}

var infousers = {
    getnumber() {
        return axios.get(protocolo+"://" + host + "/accountgetalllocaluserspercompany", axiosConfig)
    },
    getinfouser(users_dest) {
        return axios.get(protocolo+"://" + host + "/accountgetlocaluserinfo/users" + "/" + users_dest, axiosConfig)
    },
    updateinfousers(info) {
        return axios.post(protocolo+"://" + host + "/accountupdatelocalusercomplete", info, axiosConfig)
    },
    newlocaluser(info) {
        return axios.post(protocolo+"://" + host + "/accountnewlocaluser", info, axiosConfig)
    },
    resetpassword(info) {
        return axios.post(protocolo+"://" + host + "/accountresetpassword", info, axiosConfig)
    },
    deactivated(info) {
        return axios.post(protocolo+"://" + host + "/accountdeactivate", info, axiosConfig)
    },
    addworkstations(info) {
        return axios.post(protocolo+"://" + host + "/accountaddworkstations", info, axiosConfig)
    },
    getworkstations(info) {
        return axios.get(protocolo+"://" + host + "/accountgetworkstations/"+ info, axiosConfig)
    },
    // getnumber(company_code) {
    //     return axios.get("https://account.syncbox.cloud/getalluserspercompany/"+company_code, axiosConfig)
    // },
    // getinfouser(users_dest, company_code) {
    //     return axios.get("https://account.syncbox.cloud/getuserinfo/users/" + company_code + "/" + users_dest, axiosConfig)
    // },
    // updateinfousers(info, company_code) {
    //     return axios.post("https://account.syncbox.cloud/updateusercomplete/" + company_code, info, axiosConfig)
    // },
    // newlocaluser(info, company_code) {
    //     return axios.post("https://account.syncbox.cloud/newlocaluser/" + company_code, info, axiosConfig)
    // },
    // resetpassword(info) {
    //     return axios.post("https://account.syncbox.cloud/resetpassword", info, axiosConfig)
    // },

    check_access(control, action) {
        try {
            let dato_access = JSON.parse(JSON.parse(localStorage.getItem('syncbox.cloud')).access)[control]
            if (action == 'r') {
                return dato_access[0] == "1"
            } else if (action == 'w') {
                return dato_access[1] == "1"
            } else if (action == 'c') {
                return dato_access[2] == "1"
            } else if (action == 'd') {
                return dato_access[3] == "1"
            }
        } catch (ex) {
            return false
        }

    }
}


/**
 * The function `SetUserSettings` asynchronously sends user settings and code to a server endpoint for
 * storage.
 * @param code
 * @param settings
 * @returns The function `SetUserSettings` is returning a Promise object that represents the result of
 * the asynchronous operation. This Promise will resolve with the response data if the request is
 * successful, or it will reject with an error if there is an issue. If an error occurs, the function
 * will return the string "error".
 */
const SetUserSettings = async (code, settings) => {
    const info = {
        user_id: JSON.parse(localStorage.getItem('syncbox.cloud')).idUser,
        code: code,
        settings: settings,
    }

    try {
        const response = axios.post(`${account}set-user-settings`, info, axiosConfig)
        return response
    } catch (error) {
        console.error(error)
        return "error"
    }
}


/**
 * The function `GetUserSettings` retrieves user settings by sending a POST request with user ID and a
 * code.
 * @param code
 * @returns The `GetUserSettings` function is returning a Promise object that represents the result of
 * the asynchronous operation. The function will either return the response from the axios POST request
 * if successful, or the string "error" if an error occurs during the request.
 */
const GetUserSettings = async (code) => {
    const info = {
        user_id: JSON.parse(localStorage.getItem('syncbox.cloud')).idUser,
        code: code
    }

    try {
        const response = axios.post(`${account}get-user-settings`, info, axiosConfig)
        return response
    } catch (error) {
        console.error(error)
        return "error"
    }
}
export {
    infousers,
    SetUserSettings,
    GetUserSettings
}