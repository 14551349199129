<template>
  <b-navbar-nav class="align-items-lg-center">
    <b-nav-item-dropdown icon="ion ion-ios-albums">
      <template slot="button-content">
        <span
          class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
        >
          <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
            $t("planningConfigurationLabel")
          }}</span>
        </span>
      </template>
      <!-- <b-dd-item class="ml-0" to="/btc/btc" :exact="true">Bitácora</b-dd-item> -->
      <b-dd-item class="ml-0" to="/ppl/demand" :exact="true">
        {{ $t("textDemandGlider") }}
      </b-dd-item>
      <b-dd-item
        class="ml-0"
        v-if="check_access('planning_online', 'r')"
        to="/ppl/invonl"
        :exact="true"
      >
        {{ $t("textOnlineInventory") }}
      </b-dd-item>
      <b-dd-item class="ml-0" to="/ppl/oppp" :exact="true">
        {{ $t("textResourceLoad") }}
      </b-dd-item>
      <b-dd-item class="ml-0" to="/ppl/pords" :exact="true">
        {{ $t("ordersPlanning") }}
      </b-dd-item>
      <b-dd-item class="ml-0" to="/ppl/grcapacity" :exact="true">
        {{ $t("textLoad") }}
      </b-dd-item>
      <b-dd-item class="ml-0" to="/ppl/forecast_ai" :exact="true">
        {{ $t("textForecastAI") }}
      </b-dd-item>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
export default {
  props: {
    check_access: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
