const CostsRouters = [
    {
        path: 'cst',
        component: () => import('@/components/sbx/costos/cst_start.vue')
    },
    {
        path: 'cst/prb',
        component: () => import('@/components/sbx/costos/pruebacostos.vue')
    },
    {
        path: 'cst/clcc',
        component: () => import('@/components/sbx/costos/clase_cc.vue')
    },
    {
        path: 'cst/tdc',
        component: () => import('@/components/sbx/costos/tipos_de_cuentas.vue')
    },
    {
        path: 'cst/puc',
        component: () => import('@/components/sbx/costos/plan_unico_cuenta.vue')
    },
    {
        path: 'cst/mcc',
        component: () => import('@/components/sbx/costos/maestro_centro_costos.vue')
    },
    {
        path: 'cst/ca',
        component: () => import('@/components/sbx/costos/clase_de_actividad.vue')
    },
    {
        path: 'cst/syf',
        component: () => import('@/components/sbx/costos/sociedades.vue')
    },
    {
        path: 'cst/ep',
        component: () => import('@/components/sbx/costos/esquema_particion.vue')
    },
    {
        path: 'cst/bgt',
        component: () => import('@/components/sbx/costos/presupuesto.vue')
    },
    {
        path: 'cst/cmr',
        component: () => import('@/components/sbx/costos/presupuesto_cm_prd.vue')
    },
    {
        path: 'cst/prd',
        component: () => import('@/components/sbx/costos/presupuesto_cm_prd.vue')
    },
    {
        path: 'cst/cmv',
        component: () => import('@/components/sbx/costos/cvm.vue')
    },
    {
        path: 'cst/mdeu',
        component: () => import('@/components/sbx/costos/mdeu.vue')
    },
    {
        path: 'cst/cecosact',
        component: () => import('@/components/sbx/costos/reportececos.vue')
    },
    {
        path: 'cst/sbrp',
        component: () => import('@/components/sbx/costos/subrepartos.vue')
    },
]

export {
    CostsRouters
}