<template>
    <b-navbar-nav class="align-items-lg-center">
        <b-nav-item-dropdown icon="ion ion-ios-albums">
            <template slot="button-content">
                <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                    <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{ $t('iotNavbar.textRealTimeInfo') }}</span>
                </span>
            </template>
            <b-dd-item class="ml-0" to="/IoT/timeseries" :exact="true">{{ $t('iotNavbar.textRealTimeSeries') }}</b-dd-item>
            <b-dd-item class="ml-0" to="/IoT/qytimeseries" :exact="true">{{ $t('iotNavbar.textQueryTimeSeries') }}</b-dd-item>
        </b-nav-item-dropdown>
    </b-navbar-nav>
</template>

<script>
export default {
    name: 'Newfrontv3OptionsIoT',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>