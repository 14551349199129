<template>
  <b-navbar-nav class="align-items-lg-center">
    <b-nav-item-dropdown icon="ion ion-ios-albums">
      <template slot="button-content">
        <span
          class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
        >
          <!-- <img :src="require('@/vendor/avatars/default.png')" alt class="d-block ui-w-30 rounded-circle"> -->

          <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{ $t("timesLabel") }}</span>
        </span>
      </template>
      <b-dd-item
        v-if="check_access('mod_times', 'r')"
        to="/rt/edittime"
        :exact="true"
        >{{ $t("timeModificationLabel") }}</b-dd-item
      >
      <b-dd-item
        v-if="check_access('pend_app_times', 'r')"
        to="/rt/apptime"
        :exact="true"
        >{{ $t("pendingApprovalsLabel") }}</b-dd-item
      >
      <!-- <b-dd-item v-if="check_access('floor_scrap', 'r')" to="/rt/scrap" :exact="true">Historial de Cambios</b-dd-item> -->
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
export default {
  props: {
    check_access: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
